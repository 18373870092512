<template>
  <div class="app_main">
    <el-header><MyHeader /></el-header>
    <el-main
      :style="{
        height: mainStore.mainH,
        backgroundImage: `url(${mainStore.bcg_img})`,
      }"
      class="main"
    >
      <router-view />
    </el-main>
    <el-footer><MyFooter /></el-footer>
  </div>
</template>

<script setup>
import MyHeader from "./components/MyHeader.vue";
import MyFooter from "./components/MyFooter.vue";
import { useMainStore } from "./stores/mainStore";

const mainStore = useMainStore();
const getAll = mainStore.fetchAll();
</script>

<style lang="sass" scoped>
@import './assets/styles/main'
.app_main
	width: 100vw
	overflow: none

.el-main
	padding: 0 !important

.main
	width: 100vw
	background: no-repeat url("https://rasvv.ru/assets/img/background_main.jpg")
</style>
