<template>
  <div class="header" :style="{ height: mainStore.headerHpx }">
    <el-radio-group v-model="mainStore.activePage" size="large" class="tabs">
      <el-radio-button
        id="mainBtn"
        v-for="item in mainStore.myPagesJson"
        :key="item.name"
        :label="item.title"
        :value="item.name"
        class="tab"
        @click="mainStore.setPage(item)"
      >
      </el-radio-button>
    </el-radio-group>
    <!-- <el-link
      v-for="item in mainStore.myPagesJson"
      :key="item.name"
      :to="item.name"
      @click="onSetPage(item.name)"
      >{{ item.title }}</el-link -->
    <!-- > -->
  </div>
</template>

<script setup >
import { computed } from "vue";
import { useMainStore } from "../stores/mainStore";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const routeName = computed(() => route.name);
const activeName = ref("");

const mainStore = useMainStore();
</script>

<style lang="sass" scoped>
@import '../assets/styles/main'
.header, .el-header
	margin: 0
	padding: 0
	display: flex
	justify-content: center
	background-color: $bcg_header
	color: $text_header

.el-radio-group
	padding: 0
	color: $bcg_header
	font-size: 32px
	font-weight: 600
	width: 100vw
	display: flex
	justify-content: center
	gap: 2vw

.el-radio-button__inner
	width: 150px !important
	margin: 0 1vw
	text-transform: uppercase
	font-size: 32px
	font-weight: 600
	color: inherit !important
	text-align: center
</style>
