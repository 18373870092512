import './assets/styles/main.sass'
import './assets/constants/index.js'

import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);

const pinia = createPinia();

app.use(createPinia())


app.use(router)
app.use(ElementPlus)
app.mount('#app');
